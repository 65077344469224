<template>
  <div class="contact">
    <div class="page-row">
      <div class="container">
        <div class="col col--6-12">
          <div class="contact-panel">
            <h1 class="h1">Contact</h1>
            <p
              class="text description"
            >Bel of mail ons voor een afspraak en wij komen naar je toe! Of stap bij ons binnen voor een kopje koffie</p>
            <div class="contact-row">
              <img src="img/icons/address.svg" alt class="icon" />
              <div class="content">
                <h2 class="h3">Kroot.</h2>
                <p class="text">Lou Oudenoord (ingang Café Karibu)</p>
                <p class="text">Oudenoord 330</p>
                <p class="text">3513 EX Utrecht</p>
              </div>
            </div>
            <div class="contact-row">
              <img src="img/icons/email.svg" alt class="icon" />
              <div class="content">
                <a href="mailto:company@kroot.dev" class="h3">company@kroot.dev</a>
              </div>
            </div>
            <div class="contact-row">
              <img src="img/icons/phone.svg" alt class="icon" />
              <div class="content">
                <a href="tel:0031623732296" class="h3">+31 6 237 322 96</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col col--6-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d526.951836798794!2d5.108622861844421!3d52.09915639504833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66f6a5951d82f%3A0xb1efc1637b290ef!2sOudenoord%20330%2C%203513%20EX%20Utrecht!5e0!3m2!1snl!2snl!4v1574870258982!5m2!1snl!2snl"
            width="100%"
            height="620"
            frameborder="0"
            style="border:0;"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {},
  head: {
    title: {
      inner: 'Contact',
    },
    meta: [
      { name: 'description', content: 'Kroot. Contact', id: 'desc' },
      { property: 'og:description', content: 'Kroot. Specialisten in softwareontwikkeling' },
      { property: 'og:title', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:url', content: 'https://kroot.dev/contact' },
      { name: 'twitter:card', content: 'Kroot. Specialisten in softwareontwikkeling' },
    ],
    link: [{ rel: 'canonical', href: 'https://kroot.dev/contact' }],
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .container {
    margin-top: 120px;
  }

  .contact-panel {
    background-color: $darkBlue;
    height: 620px;
    padding: 80px;

    .h1,
    .h2,
    .h3,
    .text {
      color: $white;
    }

    .h1 {
      margin-bottom: 4px;
    }

    .description {
      margin-bottom: 40px;
    }

    .contact-row {
      display: flex;
      margin-bottom: 40px;

      .icon {
        height: 30px;
        margin-right: 20px;
        min-width: 30px;
      }
    }
  }
}

@include md {
  .contact {
    .contact-panel {
      padding: 40px;
      height: auto;
    }

    .col--6-12 {
      @include grid-column(12);
    }
  }
}

@include xsm {
  .contact {
    .contact-panel {
      padding: 40px;
      height: auto;
    }

    .col--6-12 {
      width: 100% !important;
      margin-left: 0 !important;
    }

    .contact-row {
      flex-direction: column;
      align-items: flex-start;
      .icon {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
